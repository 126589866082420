export default {
	redirectUri: 'http://localhost:4200/',
 	apiUri: 'https://api-cdt.alianca.com.br/intercab',
	apiMaerskUri: 'https://api-cdt.maersk.com/intercab',
	fileReference: 'environment.ts',
	ambient: 'cdt' as const,
	version: '0.0.0',
	consumerkey: 'LZCJhRHf7p74hMWQVyB79mcAMtcdncvh',
	instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
	azfUri: 'https://api-cdt.alianca.com.br/intercab/function/uploadfilestoblob',
	commercialUri: 'https://api-cdt.alianca.com.br/intercab' + '/commercial/v1/commercial/',
	postponeUri: 'https://api-cdt.alianca.com.br/intercab/postponed-validity/v1/postponed-validity/agreement/',

}